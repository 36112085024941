#loginsignupbuttons {
    display: flex;
    width: 250px;
    justify-content: space-between;
}

#AuthNav {
    display: flex;
    align-items: center;
    width: 200px;
    justify-content: space-around;
}