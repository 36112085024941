@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,200&family=Rubik:wght@700&family=Secular+One&family=Ubuntu&display=swap');

.Header {
    position: fixed; /* Set the navbar to fixed position */
    top: 0; /* Position the navbar at the top of the page */
    width: 100%; /* Full width */
}

#HeaderLower {
    display: flex;
    justify-content: space-between;
    padding: 3px;
    align-items: center;
    border-bottom: 1px solid rgb(193, 185, 239);
    background-color: white;

}

#HeaderLeft a {
    text-decoration: none;
}

#HeaderLeft h1{
    margin-left: 20px;
    color: #8370f3;
    font-family: 'Secular One', sans-serif;
    font-size: 30px;
}

#HeaderMid {
    display: flex;
    justify-content: space-evenly;
    width: 250px;
    font-family: 'Nunito Sans', sans-serif;
}

#HeaderMid h2:hover {
    color: hotpink;
}

#HeaderMid h2 {
    color: rgb(75, 75, 75);
    font-size: 18px;
}

#HeaderRight {
    display: flex;
    justify-content: space-between;
    width: 180px;
    margin-right: 20px;
}

#HeaderRight button {
    border: 2px solid black;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
}

@media only screen and (max-width: 600px) {
    #HeaderLower {
        display: flex;
        justify-content: space-evenly;
    }

    #HeaderMid {
        display: none;
    }

    #HeaderLeft h1 {
        font-size: 20px;
        margin: 0px;
    }

  }

