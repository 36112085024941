.modal {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    z-index: 1;
  }
  
  .addlistmodal {
    position:fixed;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    z-index: 2;
    animation-name: grow-thisbox;
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
    background-color: white;
    text-align: left;
    border-radius: 20px;
    width: 600px;
    padding: 10px;
  }

  .addlistmodal h1 {
    color: black;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
  }

  #submitbuttons {
    background-color: white;
    width: 100%;
    text-align: center;
    border: 2px solid black;
    border-radius: 10px;
    margin-bottom: 28px;
    padding: 20px;
    color: black;
  }

  #collectionbutton {
    background-color: #8846fb;
    width: 20%;
    font-family: 'Poppins', sans-serif;
    color: white;
    padding: 20px;
    border-radius: 10px;
    border: 0px;
    font-size: 16px;
    transition: .4s;
  }

  #collectionbutton:hover {
    background-color: #bea8fa;
  }


  @keyframes grow-thisbox {
    0% { opacity: .1; top: 45%; }
    25% { opacity: .4; }
    50% { opacity: .6; }
    70% { opacity: .8; }
    100% { 
      opacity: .9; 
    }
  }

  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }

  #listinput {
    background-color: #f6f7f5;
    box-sizing: border-box;
    border: 1px solid #f6f7f5;
    border-radius: 10px;
    width: 100%;
    padding: 20px;
    margin-bottom: 30px;
  }

  #listinput:hover {
    border: 1px solid grey;
  }

  #listinput:focus {
    border: 1px solid;
    outline-color: black;
  }
  
@media only screen and (max-width: 600px) {

  .addlistmodal {
    width: 80vw;
  }

  #listinput {
    font-size: 16px;
    padding: 10px;
  }

  #submitbuttons {
    background-color: white;
    margin-bottom: 20px;
    padding: 10px;
    font-size: 16px;
    border-radius: 10px;
  }

  #listinput:focus {
    border: 1px solid;
    outline-color: black;
  }

  #collectionbutton {
    background-color: #8846fb;
    width: 60%;
    font-family: 'Poppins', sans-serif;
    color: white;
    padding: 10px;
    border-radius: 5px;
    border: 0px;
    font-size: 16px;
  }

}



