.modal {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    z-index: 1;
  }

  #additembutton {
    background-color: rgb(0, 64, 255);
    width: 150px;
    font-family: 'Poppins', sans-serif;
    color: white;
    padding: 20px;
    border-radius: 10px;
    border: 0px;
    font-size: 16px;
    transition: .4s;
  }

  #additembutton:hover {
    background-color: rgb(165, 105, 255);
  }
  
  .projectmodal-main {
    position:fixed;
    background: rgb(138, 138, 223);
    width: 400px;
    height: 300px;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    border-radius: 8px;
    color: white;
    padding: 20px;
    z-index: 2;
    
    animation-name: grow-thisbox;
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
  }

  @keyframes grow-thisbox {
    0% { opacity: .1; top: 45%; }
    25% { opacity: .4; }
    50% { opacity: .6; }
    70% { opacity: .8; }
    100% { 
      opacity: .9; 
    }
  }

  .projectmodal-main button {
    background-color: rgb(138, 138, 223);
    padding: 3px;
    margin: 0px;
    width: 100px;
    margin-right: 8px;
    transition: all 0.5s ease-out;
  }

  .projectmodal-main button:hover {
    background-color: rgb(70, 70, 111);
  }

  .projectmodal-main input {
    width: 100%;
    height: 40px;
    background-color: aliceblue;
    border-radius: 5px;
    border: 0px;
    margin-bottom: 60px;
  }
  
  .projectmodal-main h2 {
    color: white;
    font-size: 24px;
    text-align: left;
    margin-bottom: 20px;
  }

  .projectmodal-main p {
    color: white;
    margin-bottom: 22px;
  }

  .ModalBottom {
    display: flex;
  }

  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }

  #TopText {
    font-size: 30px;
  }

  #MidText {
    margin-bottom: 20px;
  }

  .AddListItemButton button {
    width: 100px;
    height: 40px;
    font-family: 'Belanosima', sans-serif;
    font-size: 20px;
    border: 0px;
    border-radius: 10px;
    background-color: rgb(93, 50, 211);
    color: #79fa22;
    transform: 1s;
    margin-bottom: 10px;
}


.AddListItemButton button:hover {
    background-color: rgb(50, 66, 211);
    transition: 1s;
}