#trythis {
    display: flex;
    align-items: start;
    justify-content: space-between;
    width: 300px;
}

#ExternalTopSection {
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

#ExternalTopSection h3 {
    margin-bottom: 0px;
}

.TopPart {
    width: 90%;
    margin: auto;
    text-align: left;
}


@media only screen and (max-width: 600px) {

    #ExternalTopSection {
        width: 100%;
        display: inline-block;
        text-align: center;
    }

    #ExternalTopSection h1 {
        font-size: 17px;
    }

    #ExternalTopSection h3 {
        font-size: 14px;
    }

    .Profile {
        display: inline-block;
        padding-top: 40px;
    }

    .ListItem {
        margin-top: 50px;
    }

    .ListItem p {
        font-size: 14px;
    }

    #itemnote {
        padding: 3px;
    }



  }

