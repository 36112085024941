@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,200&family=Rubik:wght@700&family=Secular+One&family=Ubuntu&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Borel&display=swap');

#FooterTop {
    color: hotpink;
    text-align: center;
    padding-top: 100px;
}

#FooterTop h1 {
    margin: 80px;
    font-family: 'Secular One', sans-serif;
    font-size: 100px;
}

#FooterBottom {
    height: 300px;
    background-color: white;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 100px;
    width: 60%;
    margin: auto;
    border-radius: 10px;
}

.Footer h2 {
    margin-left: 80px;
}

.Footer p {
    font-size: 14px;
    color: grey;
    margin-left: 80px;
}

@media only screen and (max-width: 600px) {

    #Footer {
        width: 100vw;
    }

    #FooterTop {
        color: hotpink;
        text-align: center;
        padding-top: 0px;
    }

    #FooterTop h1 {
        font-size: 60px;
    }


.Footer h2 {
    margin-left: 0px;
}

.Footer p {
    margin: 0px;
}


#FooterTop h1 {
    font-size: 30px;
}

.Footer h2 {
    margin-left: 0px;
}

.Footer h2 {
    margin-left: 0px;
}

.Footer p {
    font-size: 12px;
    color: grey;
    margin-left: 0px;
}
#FooterBottom {
    display: inline-block;
    height: 100px;
    padding-top: 0px;
}

  }