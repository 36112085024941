.modal {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(52, 51, 51, 0.8);
    z-index: 1;
  }
  
  .projectmodal-main {
    position:fixed;
    background: white;
    width: 450px;
    height: 250px;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    border-radius: 30px;
    color: white;
    padding: 20px;
    z-index: 2;
    
    animation-name: grow-thisbox;
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
  }

  @keyframes grow-thisbox {
    0% { opacity: .1; top: 45%; }
    25% { opacity: .4; }
    50% { opacity: .6; }
    70% { opacity: .8; }
    100% { 
      opacity: .9; 
    }
  }

  .projectmodal-main button {
    background-color: rgb(138, 138, 223);
    padding: 3px;
    margin: 0px;
    width: 100px;
    margin-right: 8px;
    transition: all 0.5s ease-out;
  }

  .projectmodal-main button:hover {
    background-color: rgb(70, 70, 111);
  }

  .projectmodal-main input {
    width: 100%;
    height: 40px;
    background-color: aliceblue;
    border-radius: 5px;
    border: 0px;
    margin-bottom: 60px;
  }
  
  .projectmodal-main h2 {
    color: white;
    font-size: 24px;
    text-align: left;
    margin-bottom: 20px;
  }

  .projectmodal-main p {
    color: white;
    margin-bottom: 22px;
  }

  .ModalBottom {
    display: flex;
  }

  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }

  #TopText {
    font-size: 30px;
  }

  .AddListButton button {
    background-color: rgb(93, 50, 211);
    color: #79fa22;
    border: 0px;
    border-radius: 30px;
    margin-top: 20px;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    padding: 16px;
    width: 180px;
  }

  @media only screen and (max-width: 600px) {

    .projectmodal-main {
      width: 150px;
    }
    
    }