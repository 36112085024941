@import url('https://fonts.googleapis.com/css2?family=Candal&display=swap');

.FrontpageTop {
    height: 100vh;
    background-color: #011e2b;
}

.FrontpageTop h1 {
    color: #8947fb;
    margin: auto;
    padding-top: 250px;
    font-size: 100px;
    font-family: 'Candal', sans-serif;
    margin-bottom: 0px;
    padding-left: 250px;
}

.FrontpageTop h2 {
    color: white;
    font-size: 90px;
    font-family: 'Candal', sans-serif;
    margin-top: 0px;
    padding-left: 250px;
}

.FrontpageTop p {
    color: rgb(170, 222, 241);
    font-size: 20px;
    padding-left: 250px;
}

@media only screen and (max-width: 600px) {

    .FrontPageTop {
        width: 100%;
        background-color: #011e2b;
    }

    .FrontpageTop h1 {
        font-size: 45px;
        padding-left: 0px;
        text-align: center;
        padding-top: 180px;
    }

    .FrontpageTop h2 {
        color: white;
        font-size: 33px;
        font-family: 'Candal', sans-serif;
        margin-top: 0px;
        padding-left: 0px;
        text-align: center;
        padding-top: 15px;
    }

    .FrontpageTop p {
        text-align: left;
        font-size: 20px;
        padding-left: 0px;
        width: 80%;
        margin: auto;
        padding-top: 40px;
    }
  }