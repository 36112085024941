.Onboard {
    height: 100vh;
    width: 100vw;
    background-color: #0c1416;
}

.welcomefirst {
    width: 70%;
    height: 70%;
    margin: auto;
    font-family: 'Poppins', sans-serif;
    font-size: 60px;
    color: #9090ef;
    animation: fadeIn 1s;
}

.welcomefirst h1 {
    font-size: 60px;
    padding-top: 100px;
    text-align: center;
}

@keyframes fadeIn {
    0% { opacity: .2; }
    100% { opacity: 1; }
  }

.welcomesecond {
    animation: fadeIn 1s;
    text-align: center;
}


@media only screen and (max-width: 600px) {
    .Onboard {
        text-align: left;
    }


    .welcomefirst {
        margin: 0px;
        text-align: left;
        width: 100%;
    }

    .welcomefirst h1 {
        font-size: 20px;
        padding-top: 100px;
    }
    .welcomesecond {
        animation: fadeIn 1s;
        margin: 0px;
        width: 100%;
    }

    .Holder {
        width: 100vw;
        display: inline-block;
    }

  }