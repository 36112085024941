@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz@6..12&display=swap');

.WholeProfile {
    min-height: 100vh;
    background-color: #0c1416;
    padding-bottom: 200px;
}

.Profile {
    background-color: #0c1416;
    padding-top: 100px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: start;
    
}

.ProfileLeft {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    background-color: #101b1e;
    border-radius: 30px;
    padding: 30px;
}

.ProfileLeft h1 {
    color: white;
    font-family: 'Archivo Black', sans-serif;
    font-size: 60px;
}

.ProfileLeft button {
    background-color: #902ded;
    border: 0px;
    border-radius: 30px;
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    padding: 20px;
    width: 200px;
}

.ProfileLeft p {
    font-family: 'Poppins', sans-serif;
    color: rgb(180, 176, 176);
}

.ProfileRight {
    margin: auto;
    width: 80%;
}

.ListItem {
    border: #902ded;
    border-radius: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    
    margin-top: 10px;
    padding: 10px;
    justify-content: space-between;
    color: white;
    background-color: #902ded;
    font-family: 'Poppins', sans-serif;
    
}

.ListItem:hover {
    background-color: #bea8fa;
    transition: .2s;;
    transition: .2s;
    color: #902ded;
    transition: .2s;

}

.ListItem:hover a {
    color: white;
}

.ListItem a {
    text-decoration: none;
    color: white;
}

.ListItem a:hover {
    color: white;
}

.ListItemRow {
    display: flex;
    align-items: center;
}


.Profile2 {
    height: 100vh;
    background-color: #30b9de;
    display: flex;
}

.Profile3 {
    height: 100vh;
    background-color: #caed32;
    display: flex;
}

.ProfileLeftSide {
    color: #902ded;
    font-family: 'Archivo Black', sans-serif;
    font-size: 80px;
}

.ProfileTop {
    background-color: #0c1416;
    padding-top: 150px;
}

.ProfileTop h1 {
        font-size: 30px;
        color: rgb(75, 88, 209);
}

.ProfileTop h2 {
    font-family: 'Nunito Sans', sans-serif;
    color: #902ded;
    font-size: 15px;
}

.ProfileTop h3 {
    color: #fa22a4;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 17px;
}

.AddExternal {
    display: flex;
}

#itemnote {
    background-color: #011e2b;
    padding: 30px;
    border-radius: 40px;
    color: white;
    margin-bottom: 40px;
}

#ProfileTopSection {
    width: 100%;
    text-align: center;
}

#TopRow {
    display: flex;
    align-items: center;
    justify-content: space-around;
}


@media only screen and (max-width: 600px) {

    .WholeProfile {
        text-align: center;
    }

    #ProfileTopSection {
        width: 100%;
    }

    .Profile {
        display: inline-block;
    }


    .ProfileLeft h1 {
        font-size: 21px;
    }

    .ProfileLeft {
        text-align: left;
        width: 90%;
        padding: 10px;
    }


    .ListItem {
        grid-template-columns: 1fr 1fr 1fr;
        width: 100%;
        margin-top: 10px;
        padding: 3px;
    }
    
    .ProfileRight {
        margin-top: 40px;
        width: 90%;
    }

    .Profile {
        padding-bottom: 40px;
        width: 100%;
    }

    .ProfileTop {
        background-color: #0c1416;
        padding-top: 110px;
    }

    #ProfileTopSection {
        width: 100%;
        display: inline-block;
        text-align: center;
    }

    #ProfileTopSection h1 {
        font-size: 22px;
    }

    #ProfileTopSection h3 {
        font-size: 14px;
    }

    #TopRow {
        display: inline-block;
    }
 

  }
