@import url('https://fonts.googleapis.com/css2?family=Candal&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Belanosima&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

@keyframes fadeIn {
    0% { opacity: .2; }
    100% { opacity: 1; }
  }

.Nolidge {
    background-color: #e9c0e9;
    padding-bottom: 200px;
    height: 100vh;
}

.Top {
    padding-top: 150px;
    color: rgb(206, 205, 250);
    padding-bottom: 20px;
    text-align: left;
    width: 75%;
    display: flex;
    justify-content: space-between;
    margin: auto;
}

.Top h1 {
    font-size: 50px;
    text-shadow: -4px 4px #ef3550,
               -8px 8px #f48fb1,
               -12px 12px #7e57c2,
               -16px 16px #2196f3,
               -20px 20px #26c6da,
               -24px 24px #43a047,
               -28px 28px #eeff41,
               -32px 32px #f9a825,
               -36px 36px #ff5722;
    margin-bottom: 70px;
    animation: fadeIn 3s;
}

.Top h2 {
    margin-top: 15px;
    font-size: 15px;
    width: 500px;
}

.Top button {
    width: 200px;
    height: 50px;
    font-family: 'Belanosima', sans-serif;
    font-size: 20px;
    border: 0px;
    border-radius: 10px;
    background-color: rgb(93, 50, 211);
    color: white;
    margin-top: 60px;
    margin-left: 200px;
    transform: 1s;
}


.Top button:hover {
    background-color: rgb(50, 66, 211);
    transition: 1s;
}

.Lower {
    width: 75%;
    margin: auto;
}

.Card {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: auto;
    background-color: #581992;
    margin-bottom: 20px;
    border-radius: 7px;
    padding-left: 20px;
    padding-right: 8px;
    height: 72px;
    align-items: center;
}

.Card:hover {
    background-color: #902ded;
    transition: .2s;
}

.Card:hover h3 {
    color: white;
    transition: .3s;
}

.Card h2 {
    color: white;
    font-family: 'Candal', sans-serif;
    padding: 4px;
    font-size: 16px;
}

.Card h3 {
    color: rgb(164, 163, 163);
    font-family: 'Belanosima', sans-serif;
}

.Card p {
    color: aquamarine;
    font-size: 20px;
}



