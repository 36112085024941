.listtop {
    display: flex;
    align-items: center;
    justify-content: start;
}

.listtop {
    color: white;
}

.listtop h2 {
    margin-top: 0px;
    padding-top: 0px;
}


