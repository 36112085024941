.ListScroll {
    text-align: left;
    margin-left: 100px;
}

.ListScroll button {
   background-color: #0f1a1c;
   color: white;
   border: 0px;
   border-radius: 10px;
   padding: 20px;
   width: 200px;
   font-family: 'Poppins', sans-serif;
   background-color: #8947fb;
}

.linkitem {
    margin-bottom: 20px;
}

#takebutton {
    width: 200px;
    padding: 10px;

}

.ListScroll h1 {
    color: white;
}

.use {
    display: inline-block;
    background-color: #0f1a1c;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3), -1px -1px 2px rgba(12, 7, 7, 0.5);
    padding: 20px;
    border-radius: 20px;
    position: fixed;
    text-align: left;
    margin-left: 10px;
}

.use a {
    text-decoration: none;
    color: hotpink;
    width: 100px;
}

.use button {
    width: 50px;
}

.hidden {
    display: none;
}


@media only screen and (max-width: 600px) {

    .ListScroll {
        margin-top: 30px;
        text-align: center;
        margin-left: 0px;
    }

    .use {
        position: relative;
        text-align: left;
        margin: auto;
        position: absolute; 
        top: 270px;
        left: 50%;
        width: 110px;
    }

    #takebutton {
        width: 100px;
        padding: 12px;
    }



  }
