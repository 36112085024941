@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

.FrontpageJoinButton button {
    background-color: #8947fb;
    padding: 20px;
    border-radius: 10px;
    border: 0px;
    width: 400px;
    color: white;
    margin-left: 250px;
    margin-top: 40px;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    transition: .5s;
}

.FrontpageJoinButton button:hover {
    background-color: #b86ce7;
    color: white;
}

@media only screen and (max-width: 600px) {

    .FrontpageJoinButton {
        text-align: center;
    }

    .FrontpageJoinButton button {
        width: 80%;
        margin-left: 0px;
        
    }
  }