#OnboardAddInfo {
    background-color: #0c1416;
    padding: 70px;
    border-radius: 30px;
    width: 800px;
    margin: auto;
    animation: fadeIn 2s;
}


@media only screen and (max-width: 600px) {

    #OnboardAddInfo {
        width: 70%;
        margin: auto;
        padding: 0px;

    }


  }


