.modal {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    z-index: 1;
  }
  
  .externalnamemodal {
    position:fixed;
    background: white;
    width: 500px;
    height: 270px;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    border-radius: 30px;
    color: white;
    padding: 40px;
    z-index: 2;
    text-align: center;
    
    animation-name: grow-thisbox;
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
  }

  @keyframes grow-thisbox {
    0% { opacity: .1; top: 45%; }
    25% { opacity: .4; }
    50% { opacity: .6; }
    70% { opacity: .8; }
    100% { 
      opacity: .9; 
    }
  }



  .externalnamemodal input {
    width: 100%;
    height: 40px;
    background-color: #f6f7f5;
    border-radius: 15px;
    padding: 5px;
    border: 0px;
  }
  
  .projectmodal-main h2 {
    color: white;
    font-size: 24px;
    text-align: left;
    margin-bottom: 20px;
  }

  .projectmodal-main p {
    color: black;
    text-align: center;
  }

  

  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }

  #TopText {
    font-size: 30px;
  }



  #changeurl {
    color: black;
    font-weight: bold;
  }

 #thisbutton {
  background-color: white;
  border: 2px solid black;
  border-radius: 20px;
  color: black;
  width: 100%;
  transition: .2s;
 }


 #thisbutton:hover {
  background-color: rgb(231, 231, 231);
 }

 #submitbutton {
  background-color: white;
  border: 2px solid black;
  border-radius: 20px;
  color: black;
  width: 100%;
  height: 60px;
  margin-top: 30px;
  font-weight: bold;
  transition: .2s;
 }

 #submitbutton:hover {
  background-color: rgb(231, 231, 231);
 }